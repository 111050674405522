a{
  text-decoration: none;
  color: inherit;
}

.MuiAutocomplete-popper {
  min-width: 200px;
}

.swal2-container {
  z-index: 99999 !important;
}